import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { FaChevronDown, FaChevronUp, FaCheck } from 'react-icons/fa';
import "./filter-item.sass"

const FilterItem = ({ filterOptions, setFilter, filterName, singleChoice = false, preSelected = [] }) => {
  const [toggle, setToggle] = React.useState(false);
  const [filterList, setFilterList] = React.useState(preSelected);

  const handleFilter = (filterValue) => {
    //if singleChoice is true, then only one filter can be selected at a time
    if (singleChoice) {
      setFilterList([filterValue]);
      return;
    }
    if (filterList.includes(filterValue)) {
      setFilterList(filterList.filter(item => item !== filterValue));
    } else {
      setFilterList([...filterList, filterValue]);
    }
  }

  React.useEffect(() => {
    setFilter(filterList);
  }, [filterList, setFilter]);

  React.useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.closest(".filter-item") === null) {
        setToggle(false);
      }
    });

  }, []);




  return (
    <div className={`filter-item ${toggle ? "filter-item-active" : ""}`}>
      <div className={`filter-item-header ${toggle || filterList.length !== 0 ? "filter-item-header-active" : ""}`} onClick={() => setToggle(!toggle)}>
        <h5 className="h5-black">{`${filterName} ${filterList.length === 0 ? "" : "(" + filterList.length + ")"}`}</h5>
        <FaChevronUp className="chevron-up" />
      </div>
      <div className={`filter-item-body ${toggle ? "filter-item-body-active" : ""}`}>
        <div className="filter-options">
          {filterOptions.map((value, index) => {
            return (
              <div className="filter-item-body-item" key={index} onClick={() => handleFilter(value)}>
                <p>{value}</p>
                {filterList.includes(value) ? <FaCheck /> : null}
              </div>
            )
          }
          )}
        </div>
      </div>
    </div>
  );
}

export default FilterItem;