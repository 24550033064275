import React, { useEffect } from "react";
import RedirectHeader from "../components/RedirectHeader";
import { FaChevronDown } from "react-icons/fa";
import Layout from "../components/Layout";
import FilterItem from "../components/FilterItem";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import filterIcon from "../img/Filters.png"
import cross from "../img/cross.svg"
import { isMobile } from "react-device-detect";
import { FaCheck } from "react-icons/fa";
import close from "../img/close.svg";
import { Link } from "gatsby";

import "./prices.sass";


const PricesPageTemplate = ({ buyIn, discounts, paymentOptions, prices }) => {
  const [showDropdowns, setShowDropdowns] = React.useState([false, false, false]);
  const [sort, setSort] = React.useState(["Alfabetycznie"]);
  const [filterOptions, setFilterOptions] = React.useState(["Alfabetycznie", "Najniższa cena", "Najwyższa cena"]);
  const [sortedPrices, setSortedPrices] = React.useState(prices);
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [materials, setMaterials] = React.useState([]);

  const toggleFiters = () => {
    setIsFiltersOpen(!isFiltersOpen);
  }

  const showPopup = (materials) => {
    if (materials.length === 0) return;
    setMaterials(materials);
    setOpenPopup(true);
  }






  useEffect(() => {
    const sortPrices = (sortValue) => {
      const newPrices = [...prices];
      if (sortValue === "Alfabetycznie") {
        const sorted = newPrices.sort((a, b) => a.title.localeCompare(b.title));
        setSortedPrices(sorted);
      } else if (sortValue === "Najniższa cena") {
        const sorted = newPrices.sort((a, b) => a.cyclicPrice - b.cyclicPrice);
        setSortedPrices(sorted);
      } else if (sortValue === "Najwyższa cena") {
        const sorted = newPrices.sort((a, b) => b.singlePrice - a.singlePrice);
        setSortedPrices(sorted);
      }
    }
    sortPrices(sort[0]);
  }, [sort]);

  const toggleDropdown = (index) => {
    const newShowDropdowns = [...showDropdowns];
    newShowDropdowns[index] = !newShowDropdowns[index];
    setShowDropdowns(newShowDropdowns);
  }


  return (

    <>
      <div className="section-container">
        <section className="section">
          <RedirectHeader title="CENY & TERMINY" showRedirect={false} />
          <h1 style={{
            fontWeight: 400,
            marginTop: "0",
          }}>CENY</h1>
        </section>
      </div>
      <div className="section-container section-alternative full-width">
        <section className="section">
          <div className="prices-info">
            <h5 className="h5-black" style={{
              fontSize: "2rem"
            }}>NAJWAŻNIEJSZE INFORMACJE</h5>
            <div className="price-info-list">

              <div className={`price-info-item ${showDropdowns[0] ? "active" : ""}`} >
                <div className="price-info-item-header" onClick={() => toggleDropdown(0)}>
                  <p>Wpisowe</p>
                  <FaChevronDown className="chevron-down" />
                </div>
                <div className="price-info-item-content">{`Osoba zapisująca się po raz pierwszy wpłaca ${buyIn} zł wpisowego.`}</div>
              </div>

              <div className={`price-info-item ${showDropdowns[1] ? "active" : ""}`} >
                <div className="price-info-item-header" onClick={() => toggleDropdown(1)}>
                  <p>Obowiązujące zniżki</p>
                  <FaChevronDown className="chevron-down" />
                </div>
                <ul className="price-info-item-content">
                  {discounts.map((discount, index) => (
                    <li key={index}>
                      {discount.description}
                    </li>
                  ))}
                </ul>
              </div>

              <div className={`price-info-item ${showDropdowns[2] ? "active" : ""}`} >
                <div className="price-info-item-header" onClick={() => toggleDropdown(2)}>
                  <p>Dostępne formy płatności</p>
                  <FaChevronDown className="chevron-down" />
                </div>
                <ul className="price-info-item-content">
                  {paymentOptions.map((paymentOption, index) => (
                    <li key={index}>
                      {paymentOption.description}
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          </div>
        </section>
      </div>
      <div className="section-container">
        <section className="section sort-section-container">
          <div className="prices-list">
            <div className="prices-header">
              <h5 className="h5-black">CENNIK</h5>
              {
                !isMobile ? (
                  <FilterItem filterOptions={["Alfabetycznie", "Najniższa cena", "Najwyższa cena"]} filterName={"Sortuj"} setFilter={setSort} singleChoice={true} preSelected={sort} />
                ) : (
                  <div className="mobile-filters-container">
                    <p>Sortuj</p>
                    <img src={filterIcon} alt="filter icon" onClick={() => toggleFiters()} />
                  </div>
                )





              }

            </div>
            {isFiltersOpen && (
              <div className="mobile-filters">
                <div className="mobile-filters-header">
                  <p className="mobile-filters-title">Sortuj</p>
                  <img src={cross} alt="cross icon" onClick={() => toggleFiters()} />
                </div>
                {filterOptions.map((value, index) => {
                  return (
                    <div className="filter-item-body-item" key={index} onClick={() => setSort([value])}>
                      <p>{value}</p>
                      {sort.includes(value) ? <FaCheck /> : null}
                    </div>
                  )
                }
                )}
              </div>
            )}
            <div className="prices-list-container">
              <div className="prices-list-header">
                <p>RODZAJ ZAJĘĆ</p>
                <p>ZAJĘCIA CYKLICZNE</p>
                <p>ZAJĘCIA POJEDYNCZE</p>
              </div>
              {sortedPrices.map((price, index) => {
                const { title, cyclicPrice, singlePrice, paidMaterials, slug } = price;
                return (
                  <div key={index}>
                    <div className="prices-list-item">
                      <Link to={slug}><p className="h5-black">{title}</p></Link>
                      <p className={`cyclic-price ${paidMaterials?.length > 0 ? "active" : ""}`} onClick={() => showPopup(paidMaterials)}>{isMobile ? "CYKLICZNE · " + cyclicPrice : cyclicPrice} zł</p>
                      <p className={`single-price ${paidMaterials?.length > 0 ? "active" : ""}`} onClick={() => showPopup(paidMaterials)}>{isMobile ? "POJEDYNCZE · " + singlePrice : singlePrice} zł</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <p className="comment">Dopłaty</p>
        </section>
      </div>
      {
        openPopup && (
          <div className="popup-container">
            <div className="popup">
              <div className="popup-header">
                <p className="h5-black">DOPŁATY: </p>
                <img src={close} style={{ position: "absolute", top: "32px", right: "32px", cursor: "pointer" }} alt="close icon" onClick={() => setOpenPopup(false)} />
              </div>
              {
                materials.map((material, index) => (
                  <div className="popup-item" key={index}>
                    <p style={{ fontWeight: "600" }}>{material.price} ZŁ </p>
                    <p style={{ padding: "0 .5rem" }}>-</p>
                    <p>{material.material}</p>
                  </div>
                ))
              }


            </div>
          </div>
        )

      }
    </>
  );
}

PricesPageTemplate.propTypes = {
  buyIn: PropTypes.number,
  discounts: PropTypes.array,
  paymentOptions: PropTypes.array,
  prices: PropTypes.array,
};



const PricesPage = ({ data }) => {
  const { buyIn, discounts, paymentOptions } = data.markdownRemark.frontmatter;
  const { edges } = data.allMarkdownRemark;
  const prices = edges.map(edge => edge.node);
  const removePricesDuplicates = (prices) => {
    const newPrices = []
    prices.forEach(price => {

      if (newPrices.filter(newPrice => newPrice.title.toLowerCase().trim() === price.frontmatter.title.toLowerCase().trim() && newPrice.cyclicPrice === price.frontmatter.cyclicPrice && newPrice.singlePrice === price.frontmatter.singlePrice).length === 0) {
        newPrices.push({ ...price.frontmatter, slug: price.fields.slug });
      }
    });
    return newPrices;
  }
  return (
    <Layout>
      <PricesPageTemplate buyIn={buyIn} discounts={discounts} paymentOptions={paymentOptions} prices={removePricesDuplicates(prices)} />
    </Layout>
  );
}

PricesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PricesPage;

export const pageQuery = graphql`
          query PricesPageTemplate {
            markdownRemark(frontmatter: {templateKey: {eq: "prices-page"}}) {
            frontmatter {
            buyIn
        discounts {
            description
          }
          paymentOptions {
            description
          }
      }
    }
          allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "class"}}}) {
            edges {
            node {
              fields {
              slug
             }

            id
          frontmatter {
            title
            singlePrice
          cyclicPrice
            paidMaterials {
              price
              material
            }
          }
        }
      }
    }
  }
          `